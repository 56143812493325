// ||||| HOMEPAGE |||||

.homepage {
    margin: 0;
    padding: 0;
    overflow: hidden;

    & .container {
        padding-bottom: 50px;
    }
}

@media screen and (min-width: 768px) {
    .call-recrutamento-container {
        padding-top: 50px;
        max-width: 100vw;
        width: 100vw;
        padding-left: 250px;
        padding-right: 250px;
    }
}