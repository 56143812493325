.partners-row {
    margin-bottom: 45px;

    & div {
      display: flex;
      justify-content: center;

      & img {
        padding: 2px;
      }
    }
}

.parceiros-desktop {
    display: none;
}

@media screen and (min-width: 768px) {

    .parceiros-desktop {
        display: block;

        & li.react-multi-carousel-item.react-multi-carousel-item {
            display: flex;
            align-items: center;
        }
    }

    .parceiros-mobile {
        display: none;
    }

    .marquee-container {
        width: 100%;
        overflow: hidden;
        position: relative;
      }
      
      .marquee-content {
        display: flex;
        animation: marquee 30s linear infinite;
        width: fit-content;
      }
      
      .marquee-container:hover .marquee-content {
        animation-play-state: paused;
      }
      
      .logo-item {
        padding: 0 100px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      
      .logo-item img {
        max-height: 80px;
        min-height: 50px;
        width: auto;
      }
      
      @keyframes marquee {
        0% {
          transform: translateX(0);
        }
        100% {
          transform: translateX(-50%);
        }
      }
      
}

