
@use '../../abstracts' as *;

.contacts {
    padding-bottom: 50px;
    min-height: calc(100vh - 110px);
}

.form-contact {

    & .form-contact-input {
        padding: 16px;
        border-radius: 8px;
        font-size: 1.4rem;
    }
}

.contact-info {
    & .contact-info-socials {
        display: flex;
        width: 100%;
        justify-content: center;
        margin-top: 40px;
        order: 1;


        & .social-icon {
            display: flex;
            align-items: center;
            
            & a {
                width: 45px;
                height: 45px;
                background: $yellow;
                display: inline-flex;
                border-radius: 50%;
                align-items: center;
                justify-content: center;
                line-height: 1;
                border: 1px solid $yellow;
                margin-right: 10px;
            
                &::before {
                content: "";
                width: 40px;
                height: 40px;
                position: absolute;
                background-color: $white;
                border-radius: 50%;
                transform: scale(0);
                transition: 0.3s ease-in-out;
                }
            
                &:hover::before {
                transform: scale(1);
                background-color: $black;
                }
            
                & img {
                width: 60%;
                z-index: 1;
                transition: 0.3s ease-in-out;
                filter: brightness(0) saturate(100%) invert(13%) sepia(0%) saturate(0%) hue-rotate(196deg) brightness(92%) contrast(79%);
                }
            
                &:hover img {
                filter: brightness(0) saturate(100%) invert(100%) sepia(97%) saturate(11%) hue-rotate(220deg) brightness(104%) contrast(105%);
                }
            }
        }

    }
}


.contact-details-item {
    display: flex;
    align-items: center;
    margin: 16px;

    & .contact-details-item-icon {
        width: 24px;
        filter: brightness(0) saturate(100%) invert(63%) sepia(92%) saturate(338%) hue-rotate(360deg) brightness(105%) contrast(95%);

    }

    &  .contact-details-item-text {
        margin-bottom: 0;
        margin-left: 8px;
        font-size: 1.6rem;
    }
}

.contact-map {
    width: 100%;
    border-radius: 8px;
    order: 3;

    & iframe {
        border-radius: 8px;
    }
}
.contacts-cell {
    padding: 0 26px;
}

.contact-details {
    order: 2;
}

.contacts-desktop {
    display: none;
}

.contacts-desktop .form-contact {
    width: 100%;
}

@media screen and (min-width: 768px) {
    .contacts-desktop {
        display: flex;

        & .row .col-md-6 {
            padding-left: 26px;
            padding-right: 26px;
        }

        & .contact-info-socials {
            display: flex;
            gap: 16px;
            align-items: center;
            justify-content: center;

            & .social-icon {
                width: 32px;
                height: 32px;

                & a {
                    margin-right: 0;
                    width: 32px;
                    height: 32px;
                background: $yellow;
                display: inline-flex;
                border-radius: 50%;
                align-items: center;
                justify-content: center;
                line-height: 1;
                border: 1px solid $yellow;
            
                &::before {
                content: "";
                width: 40px;
                height: 40px;
                position: absolute;
                background-color: $white;
                border-radius: 50%;
                transform: scale(0);
                transition: 0.3s ease-in-out;
                }
            
                &:hover::before {
                transform: scale(1);
                background-color: $black;
                }
            
                & img {
                width: 60%;
                z-index: 1;
                transition: 0.3s ease-in-out;
                filter: brightness(0) saturate(100%) invert(13%) sepia(0%) saturate(0%) hue-rotate(196deg) brightness(92%) contrast(79%);
                }
            
                &:hover img {
                filter: brightness(0) saturate(100%) invert(100%) sepia(97%) saturate(11%) hue-rotate(220deg) brightness(104%) contrast(105%);
                }
                    
                }
            }
        }

        & .contact-form-message div {
            height: 100%;
        }
        
        & .contact-details-item {
            margin: 0;
            flex-direction: row-reverse;
            gap: 10px;
        }

        .contact-details {
            display: flex;
            flex-direction: column;
            gap: 4px;
        }

        & .row .col-md-6 {
            gap: 56px;
        }
    }

    .contacts-mobile {
        display: none;
    }

    .contacts.container {
        padding-left: 12vw;
        padding-right: 12vw;
        width: 100vw;
        max-width: 100vw;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .contacts-container {
        width: 100%;
    }

    .contact-info .contact-info-socials{
        order: 3;
        gap: 10px;
    }

    .contact-details{
        order: 1;
    }

    .contact-map{
        order: 2;
        min-height: 250px;

        iframe {
            width: 100%;
            border-radius: 8px;
            height: 100%;
        }
    }

    .social-icon {
        width: 24px;
        height: 24px;
    }

    .contact-info .contact-info-socials .social-icon a {
        width: 24px;
        height: 24px;
        margin: 0;
    }
}


