.wip-container {
    & img {
      width: 100%;
    }

    height: calc(100vh - 110px);
}


.wip-container-desktop {
  & img {
    height: 70vw;
  }

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: calc(100vh - 110px);

  & button.btn.btn-primary {
    margin-bottom: 30px;
  }
}