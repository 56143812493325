@media screen and (min-width: 768px) {
    .container-desktop {
        padding-left: 12vw;
        padding-right: 12vw;
        max-width: 100vw;
        width: 100vw;

        &.section-alumni {
            //padding-left: 12vw !important;
            //padding-right: 12vw !important;
            max-width: 100vw;
            width: 100vw;

            & .title {
                padding-left: 12vw !important;
                padding-right: 12vw !important;
            }
        }
    }

    
}
