@use '../../abstracts' as *;

// ||||| NAVBAR |||||

.navbar{

    background-color: $white;
    --bs-navbar-padding-x: 0;
    --bs-navbar-padding-y: 0;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.25);
  
    & .container {
      justify-content: space-between;
      padding: 0;
  
      & .navbar-toggler {
        border: none;
        padding: 1rem 0;
        padding-right: calc(var(--bs-gutter-x));

        @media screen and (min-width: 768px) {
          padding-left: 10px;
        }
  
        & .navbar-toggler-icon {
          width: 3rem;
          height: 2.7rem;
        }
  
        &:focus {
          box-shadow: none;
        }
      }
  
      & .navbar-brand {
        padding: 1rem 0;
        padding-left: calc(var(--bs-gutter-x));
      }
  
      & .navbar-collapse {
        background-color: $yellow;
  
        &.show {
          background-color: $yellow;  
          font-weight: 600;
          font-size: 1.6rem;
        }
  
        & a {
          text-decoration: none;
          color: $white;
          font-weight: 750;
          font-size: 3.2rem;
          text-align: left;

          @media screen and (min-width: 768px) {
            text-align: right;
            margin-right: 8vw;
          }
        }
  
        & .nav-link {
          padding: calc(2.5 * var(--bs-nav-link-padding-y)) 24px;
        }
      }
    }
  
    & .navbar-collapse {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1000;
      background-color: $yellow;
      display: flex;
      justify-content: center;
      align-items: center;
      transform: translateX(100%); 
      transition: transform 0.3s ease-out;

      @media  screen and (min-width: 768px) {
        justify-content: end;
      }

      &.show {
        transform: translateX(0) scale(1);
  
        & .close-btn {
          opacity: 1;
          pointer-events: all;
        }
      }
  
      & a {
        display: inline-block;
      }
  
      & .close-btn {
        position: absolute;
        top: 10px;
        right: 10px;
        font-size: 4rem;
        width: 4rem;
        height: 4rem;
        color: #fff;
        transition: opacity 0.3s ease-in-out;
        opacity: 0;
        pointer-events: none;
        background-color: transparent;
        border: none;
  
        & img {
          position: absolute;
          filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(235deg) brightness(104%) contrast(102%);
          top: 12px;
          right: 12px;
          width: 24px;
          height: 24px;
        }
      }
    }
  }

@media screen and (min-width: 768px) {
  .navbar {
    & .container {
      padding-left: 45px;
      padding-right: 45px;
      width: 100vw;
      max-width: 100vw;
    }
  }
}


.desktop-nav {
  display: none;

  @media screen and (min-width: 768px) {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: auto; /* Isso empurra o desktop-nav para a direita */
    margin-right: 10px; /* Espaço entre os links e o botão toggle */

    & a {
      padding-left: 10px;
      padding-right: 10px;
      font-size: 1.6rem;
      text-decoration: none;
      font-weight: 550;
    }
  }
}

/* Adicione esta regra para posicionar o botão toggle à direita em desktop */
@media screen and (min-width: 768px) {
  .navbar .container .navbar-toggler {
    margin-left: 0; /* Remove qualquer margem à esquerda */
    order: 3; /* Garante que o botão fique por último na ordem dos flex items */
  }
  
  .navbar .container {
    display: flex;
    justify-content: flex-start; /* Altera de space-between para flex-start */
    
    & .navbar-brand {
      margin-right: auto; /* Em telas maiores, isso empurra tudo para a direita do logo */
    }
  }
}
