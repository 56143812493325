@use 'colors' as *;

.mt-50 {
    margin-top: 50px;
}

.mt-40 {
    margin-top: 40px;
}

.mb-40 {
margin-bottom: 40px;
}

.mb-50 {
    margin-bottom: 50px;
}

.mb-30 {
margin-bottom: 30px;
}

.mb-20 {
    margin-bottom: 20px;
    }
    

.pt-50 {
padding-top: 50px;
}

.pb-30 {
padding-bottom: 30px;
}

.pb-50 {
padding-bottom: 50px;
}

.pb-100 {
    padding-bottom: 100px;
}

.yellow-text {
    color: $yellow;
    font-weight: bold;
}

.yellow-text-regular {
    color: $yellow;
}

.mob-col {
    flex-direction: column;
}

@media screen and (min-width: 768px) {
    .mob-col {
        flex-direction: row;

        & div {
            padding: 0 15px;
        }
    }
}

.mobile-only {
    display: block;

    @media screen and (min-width: 768px) {
        display: none;
    }
}

@media screen and (min-width: 768px) {
    .container-desktop {
        padding-left: 12vw;
        padding-right: 12vw;
        max-width: 100vw;
        width: 100vw;
    }
}

.full-width {
    width: 100vw;
    max-width: 100vw;
    padding-left: 0;
    padding-right: 0;
}