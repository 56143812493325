@use '../../abstracts' as *;

// ||||| FOOTER |||||

.footer {
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
    // position: fixed;
    // bottom: 0;
    // right: 0;
    // left: 0;
    z-index: 900;
    background-color: $white;
    height: 55px;
  
    & .container {
      padding: 15px;
  
      & p {
        font-size: 1.2rem;
        letter-spacing: -1px;
        margin-bottom: 4px;
      }
  
      & .text-end {
        display: inline-flex;
      }
    }
  }
  
  .social-icons {
    display: flex;
    justify-content: flex-end;
    padding: 0;
  }
  
  .social-icon {
    display: flex;
    align-items: center;
  
    & a {
      width: 24px;
      height: 24px;
      background: #333333;
      display: inline-flex;
      border-radius: 50%;
      align-items: center;
      justify-content: center;
      line-height: 1;
      border: 1px solid rgba(255, 255, 255, 0.5);
      margin-right: 10px;
  
      &::before {
        content: "";
        width: 36px;
        height: 36px;
        position: absolute;
        background-color: $white;
        border-radius: 50%;
        transform: scale(0);
        transition: 0.3s ease-in-out;
      }
  
      &:hover::before {
        transform: scale(1);
      }
  
      & img {
        width: 50%;
        z-index: 1;
        transition: 0.3s ease-in-out;
      }
  
      &:hover img {
        filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(98%) hue-rotate(346deg) brightness(95%) contrast(86%);
      }
    }
  }
  


  .footer-social-icons {
    display: flex;
    justify-content: flex-end;
    padding: 0;
  }
  
  .footer-social-icon {
    display: flex;
    align-items: center;
  
    & a {
      width: 24px;
      height: 24px;
      background: #333333;
      display: inline-flex;
      border-radius: 50%;
      align-items: center;
      justify-content: center;
      line-height: 1;
      border: 1px solid rgba(255, 255, 255, 0.5);
      margin-right: 10px;
  
      &::before {
        content: "";
        width: 36px;
        height: 36px;
        position: absolute;
        background-color: $white;
        border-radius: 50%;
        transform: scale(0);
        transition: 0.3s ease-in-out;
      }
  
      &:hover::before {
        transform: scale(1);
      }
  
      & img {
        width: 50%;
        z-index: 1;
        transition: 0.3s ease-in-out;
      }
  
      &:hover img {
        filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(98%) hue-rotate(346deg) brightness(95%) contrast(86%);
      }
    }
  }
  