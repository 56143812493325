.quem-somos {
    z-index: 20;
}

@media screen and (min-width: 768px) {
    .quem-somos {

        max-width: 100vw;
        width: 100vw;
        padding-left: 250px;
        padding-right: 250px;

        & p {
            font-size: 1.6rem;
        }
    }
}