.servicos {

    & .super-title-under {
        margin-bottom: 0;
    }

    & .slider {
        margin: 0 20px;
        overflow: "hidden";
        padding: 2rem 0;
      
      
        & img {
            width: 30%;
            border-radius: 0;
        }
        
    }
      
    & .react-multi-carousel-list {
        padding: 0rem 0 2rem 0;
    }
    
    & .slider-arrow {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;
    
        &--left {
            left: 12px;
        }
    
        &--right {
            right:12px;
        }
    }

    & .slider-servicos-title {
        font-size: 2rem;
        font-weight: 500;
        margin-top: 20px;
        text-align: center;

        @media  screen and (min-width: 768px) {
            font-size: 2.4rem;
            font-weight: 700;
        }
    }
    
}

.servicos-grid {
    display: none;
}

@media screen and (min-width: 768px) {
    .servicos-carousel {
        display: none;
    }

    .servicos-grid {
        display: flex;

        & img {
            width: 100px;
            height: 100px;
        }

        & .container {
            padding: 0;
            max-width: 100%;

            & .col-md-6 {
                padding: 0 15px;
            }
        }
    }
}