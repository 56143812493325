@use '../../abstracts' as *;

@keyframes slideLeft {
  0% {
      transform: translateX(0);
  }
  100% {
      transform: translateX(-100%);
  }
}

// ||||| BANNER |||||



.banner {
    height: 110vh;
    width: 150vw;
    overflow: hidden;
    transform: translate(-17%, 0);
    position: relative;
    margin-top: 0;
    padding-top: 0;
    background: $grey;

    .banner-background {
      position: relative;
      height: 100%;
      width: 100%;
      top: -5vh;

      & .background-destaque {
        position: absolute;
        left: 16%;
        z-index: 2;
        width: 100%;
        top: -5vh;
      }

      & .background-pattern {
        position: absolute;
        top: 25vh;
        left: 16%;
        width: 100%;
        height: 100%;
        z-index: 1;
        background-image: url("../../../../src/assets/img/Background_Loop_Cinza.svg");
        background-repeat: repeat;
        background-size: auto;
        transform: translateZ(0);
        backface-visibility: hidden; 
        font-family: 'Raleway';
      }
    }
    
    & .btn {
      position: absolute;
      left: 50%;
      top: 35vh;
      z-index: 3;
      transform: translateX(-50%);
      width: 50%;
      font-size: 2.2rem;
      padding: 18px 0;

      @media screen and (min-width: 768px) {
        width: 80%;
        max-width: 380px;
      }
    }
  }

.circle-line-container {
    position: absolute;
    left: 50%;
    height: 34vh;
    bottom: 10vh;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 10;
}

.vertical-line {
    width: 6px;
    height: 40vh;
    bottom: 5vh;
    background-color: black;
}

.yellow-circle {
    width: 40px;
    height: 40px;
    background-color: $yellow;
    border-radius: 50%;
    position: absolute;
    bottom: 34vh;
    transform: translateX(-50%);
    left: 50%;
}

.black-triangle-circle {
  width: 48px;
  height: 24px;
  background-color: $yellow;
  border-radius: 50%;
  position: absolute;
  bottom: -4vh; 
  left: 50%;
  transform: translateX(-50%);
  z-index: 8;
}


.black-triangle {
  width: 0;
  height: 0;
  border-left: 80px solid transparent;
  border-right: 80px solid transparent;
  border-bottom: 72px solid black;
  position: absolute;
  bottom: -2.5vh;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9;
}

.yellow-wavy-line {
  --s: 44px; /* size of the wave */
  --b: 5px; /* thickness of the line */
  --m: 0.4; /* curvature of the wave [0 2] */

  position: absolute;
  bottom: 15vh;
  
  --R: calc(var(--s)*sqrt(var(--m)*var(--m) + 1) + var(--b)/2);
  height: calc(2.2*var(--R));
  width: 100vw;
}

.white-wavy-line {
  --s: 44px; /* size of the wave */
  --b: 5px; /* thickness of the line */
  --m: 0.4; /* curvature of the wave [0 2] */

  position: absolute;
  bottom: 16vh;
  --R: calc(var(--s)*sqrt(var(--m)*var(--m) + 1) + var(--b)/2);
  
  height: calc(2*var(--R));
  width: 100vw;
}

.white-wavy-line-2 {
  --s: 44px; /* size of the wave */
  --b: 5px; /* thickness of the line */
  --m: 0.4; /* curvature of the wave [0 2] */

  position: absolute;
  bottom: 16vh;
  --R: calc(var(--s)*sqrt(var(--m)*var(--m) + 1) + var(--b)/2);
  
  height: calc(2*var(--R));
  width: 100vw;
}

@keyframes yellowwaveAnimation {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: -275px 0;
  }
}

@keyframes whitewave1Animation {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: -100vw 0;
  }
}

@keyframes whitewave2Animation {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: 100vw 0;
  }
}


.yellow-wavy-line {
  background: url('../../../assets/img/banner-yellow-line.svg') repeat-x;
  animation: yellowwaveAnimation 4s linear infinite;
  width: 100vw;

  @media screen and (min-width: 768px) {
    background: url('../../../assets/img/banner-yellow-line-desktop.svg') repeat-x;
  }
}

.white-wavy-line {
  background: url('../../../assets/img/banner-white-line.svg') repeat-x;
  animation: whitewave1Animation 8s linear infinite;
  width: 100vw;

  @media screen and (min-width: 768px) {
    background: url('../../../assets/img/banner-white-line-desktop.svg') repeat-x;
  }
}

.white-wavy-line-2 {
  background: url('../../../assets/img/banner-white-line.svg') repeat-x;
  animation: whitewave2Animation 4s linear infinite;
  width: 100vw;

  @media screen and (min-width: 768px) {
    background: url('../../../assets/img/banner-white-line-desktop.svg') repeat-x;
  }
}


.banner-bottom-triangle {
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
  height: 8vh;
  overflow: hidden;
  z-index: 9;
  
  &::after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-left: 58vw solid transparent;
    border-right: 58vw solid transparent;
    border-top: 8vh solid white;
    left: 50%;
    top: 0.5vh;
    transform: translateX(-50%) rotate(180deg);
  }
}


// ||||| MOBILE |||||

@media screen and (min-width: 768px) {
  .banner {
    background-image: url("../../../../src/assets/img/banner.svg");
    width: 100vw;
    transform: translate(0, 0);
    background-size: 250vh auto;
    background-position: center calc(50% - 30vh);
    transform: translateZ(0);
    
    .banner-background {

      display: none;
/*
      & .background-pattern {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background-image: url("../../../../src/assets/img/Background_Loop_Cinza.svg");
        background-size: 800px;
        background-repeat: repeat;
        background-position: center;
        z-index: 1;
      }

      & .background-destaque {
        position: absolute;
        left: 58.35%;
        transform: translateX(-50%);
        z-index: 2;
        width: 800px;
        top: -5px;
      }*/
    }
  }
}

@media screen and (min-width: 768px) {
  .yellow-wavy-line,
  .white-wavy-line,
  .white-wavy-line-2 {
    --s: 88px; /* Size of the wave */
    --b: 6px; /* Thickness of the line */
    height: calc(2.5*var(--R));
    width: 200vw;
    transform: none; /* Remove the scaling */
  }
  
  .yellow-wavy-line {
    background-size: 550px 100%; /* Set explicit background size instead of scaling */
    background-repeat: repeat-x;
    bottom: 9vh;
    animation: yellowwaveAnimation 8s linear infinite;
  }
  
  .white-wavy-line {
    background-size: 600px 100%; /* Set explicit background size instead of scaling */
    background-repeat: repeat-x;
    bottom: 10vh;
    animation: whitewave1Animation 12s linear infinite;
  }
  
  .white-wavy-line-2 {
    background-size: 600px 100%; /* Set explicit background size instead of scaling */
    background-repeat: repeat-x;
    bottom: 10vh;
    animation: whitewave2Animation 8s linear infinite;
  }
  
  /* For the yellow wave */
  @keyframes yellowwaveAnimation {
    0% {
      background-position: 0 0;
    }
    100% {
      background-position: -550px 0; /* Move exactly one pattern width */
    }
  }
  
  /* For the first white wave */
  @keyframes whitewave1Animation {
    0% {
      background-position: 0 0;
    }
    100% {
      background-position: -600px 0; /* Move exactly one pattern width */
    }
  }
  
  /* For the second white wave */
  @keyframes whitewave2Animation {
    0% {
      background-position: 0 0;
    }
    100% {
      background-position: 600px 0; /* Move in opposite direction */
    }
  }
}

@media screen and (min-width: 1200px) {
  .yellow-wavy-line,
  .white-wavy-line,
  .white-wavy-line-2 {
    --s: 132px; /* Larger size for bigger screens */
    --b: 8px; /* Slightly thicker lines for bigger screens */
  }
  
  .yellow-wavy-line {
    background-size: 825px 100%; /* 550px × 1.5 = 825px (50% larger) */
  }
  
  .white-wavy-line,
  .white-wavy-line-2 {
    background-size: 900px 100%; /* 600px × 1.5 = 900px (50% larger) */
  }
  
  /* Update animations for larger sizes */
  @keyframes yellowwaveAnimation {
    0% {
      background-position: 0 0;
    }
    100% {
      background-position: -825px 0;
    }
  }
  
  @keyframes whitewave1Animation {
    0% {
      background-position: 0 0;
    }
    100% {
      background-position: -900px 0;
    }
  }
  
  @keyframes whitewave2Animation {
    0% {
      background-position: 0 0;
    }
    100% {
      background-position: 900px 0;
    }
  }
}
