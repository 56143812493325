@use "../abstracts" as *;

.accordion {
  & .accordion-button {
    font-size: 1.6rem;
    font-weight: 600;
    display: flex;

    @media screen and (min-width: 768px) {
      font-size: 1.8rem;

      & .side-yellow-text {
        font-size: 1.8rem;
      }
    }

    &::after {
      background-size: calc(2 * var(--bs-accordion-btn-icon-width));
      width: calc(2 * var(--bs-accordion-btn-icon-width));
      height: calc(2 * var(--bs-accordion-btn-icon-width));
    }

    &:not(.collapsed) {
      background-color: $white;
      color: $black;
    }

    &:focus {
      box-shadow: none;
    }
  }

  & .accordion-header {
    @media screen and (min-width: 768px) {
      font-size: 1.8rem;
    }
  }
}

.accordion {
  & .accordion-item {
    border: none;
    border-bottom: solid black 1px;
    border-radius: 0;

    &:nth-child(1) {
      border-top: solid black 1px;
    }

    &:nth-last-child(1) {
      border-bottom: solid black 1px;
    }
  }

  & .accordion-body {
    font-size: 1.4rem;
    text-align: start;
    width: 85%;

    @media screen and (min-width: 768px) {
      font-size: 1.6rem;
    }
  }
}

.dark-container {
  & .accordion {
    background-color: $black;

    & .accordion-item {
      background-color: $black;
      border-bottom: solid white 1px;

      &:nth-child(1) {
        border-top: solid white 1px;
      }

      & .accordion-header {
        background-color: $black;
        color: $white;
      }

      & .accordion-button {
        background-color: $black;
        color: $white;
        &::after {
          filter: brightness(0) saturate(100%) invert(100%) sepia(100%)
            saturate(2%) hue-rotate(22deg) brightness(103%) contrast(101%);
        }
      }

      & .accordion-body {
        background-color: $black;
        color: $white;
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .accordion {
    display: flex;
    flex-direction: column;
    align-items: center;

    & .accordion-item {
      max-width: 50vw;
      min-width: 50vw;
    }
  }
}
