@use '../../abstracts' as *;

.cta-recrutamento {
    font-size: 1.8rem;
}

.list {
    display: flex;

    & li {
        line-height: 16px;
    }
}

@media screen and (min-width: 768px) {
    .recrutamento-container {
        padding-left: 12vw;
        padding-right: 12vw;
        width: 100vw;
        max-width: 100vw;

        & .subtitle {
            font-size: 2.4rem;
            color: $yellow;
        }

        & .accordion-button {
            font-size: 1.8rem;

            & p {
                font-size: 1.8rem;
                margin: 4px 0;
            }
        }
    }

    .recrutamento-processo-container {
        padding-left: 0;
        padding-right: 0;
        width: 100vw;
        max-width: 100vw;

        & .title, & p , & .dark-subtitle {
            padding-left: 12vw;
            padding-right: 12vw;
            width: 100vw;
            max-width: 100vw;
        }

        & .list ol li {
            padding-left: calc(12vw + 32px);
            padding-right: 12vw;
            width: 100vw;
            max-width: 100vw;
            padding-top: 25px;
            padding-bottom: 24px;
            font-size: 1.6rem;

            &::before {
                margin-left:12vw;
            }
        }
    }

    .list {
        width: 100vw;
        max-width: 100vw;

        & ol {
            position: relative;
            left: 0;
            margin-left: 0;

            & li {
                max-width: 100vw;
                width: 100vw;
                margin-left: 0;
            }
        }
    }
}
