@use '../../abstracts' as *;

.cta {
    text-align: center;
    font-size: 2rem;
    font-weight: 700;
    text-transform: uppercase;

    @media  screen and (min-width: 768px) {
      font-size: 2.4rem;
    }
  }