.alumni-carousel {
  width: 100%;
  position: relative;
  overflow: hidden;
  padding: 40px 0;
}

.alumni-carousel-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
}

.alumni-item {
  position: relative;
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.alumni-item img {
  display: block;
  border-radius: 50%;
  object-fit: cover;
  transition: all 0.3s ease;
}

.alumni-center {
  z-index: 10;
  transform: scale(1.2);
  margin: 0 180px;
}

.alumni-center img {
  width: 300px;
  height: 300px;
}

.alumni-info {
  text-align: center;
  margin-top: 15px;
  width: 100%;
}

.alumni-info h4 {
  margin: 0;
  font-weight: bold;
}

.alumni-info p {
  margin: 5px 0 0;
}

.alumni-left, .alumni-right {
  z-index: 5;
}

.alumni-left img, .alumni-right img {
  width: 120px;
  height: 120px;
  opacity: 0.8;
}

.alumni-far-left, .alumni-far-right {
  z-index: 1;
}

.alumni-far-left img, .alumni-far-right img {
  width: 120px;
  height: 120px;
  opacity: 0.6;
}

.alumni-far-left {
  margin-right: 30px;
}

.alumni-left {
  margin-right: 60px;
}

.alumni-right {
  margin-left: 60px;
}

.alumni-far-right {
  margin-left: 30px;
}

.alumni-arrow {
  background: none;
  border: none;
  cursor: pointer;
  padding: 10px;
  z-index: 20;
  display: flex;
  align-items: center;
  justify-content: center;
}

.alumni-arrow img {
  width: 23px;
  height: 39px;
  border-radius: 0;
}

.alumni-arrow-left {
  margin-right: 60px;
}

.alumni-arrow-right {
  margin-left: 60px;
}
