@use '../../abstracts' as *;

// TEXT STYLE
.title {
    text-align: start;
    font-weight: 800;
    font-size: 3.2rem;
    margin-bottom: 40px;
    padding-top: 40px;
  
    &::after {
      content: "";
      width: 100px;
      height: 10px;
      background-color: $yellow;
      display: block;
    }
  }
  
h4 {
  color: $yellow;
  font-weight: 700;
  font-size: 2rem;
  text-align: start;

  @media screen and (min-width: 768px) {	
    font-size: 2.2rem;
  }
}

.super-title-over {
    text-align: start;
    font-weight: 800;
    font-size: 3.2rem;
    padding-top: 40px;
}

.super-title-under {
  text-align: start;
  font-weight: 800;
  font-size: 4.5rem;
  color: $yellow;
  margin-bottom: 35px;

  &-recrutamento {
    text-align: start;
    font-weight: 800;
    font-size: 3.2rem;
    color: $yellow;
    margin-bottom: 35px;
  }
}

.dark-subtitle {
  color: $black;
  margin-bottom: 12px;
}

.subtitle {
  color: $white;
}


.fs-button {
  font-size: 1.8rem !important;

  @media screen and (min-width: 768px) {
    font-size: 2.2rem !important;
  }
}

.fs-underlined-title {
  font-size: 3.2rem !important;
}

.fs-paragraph {
  font-size: 1.4rem !important;

  @media screen and (min-width: 768px) {
    font-size: 1.8rem !important;
  }
}

.fs-number-label {
  font-size: 1.2rem !important;

  @media screen and (min-width: 768px) {  
    font-size: 1.4rem !important;
  }
}

.fs-number {
  font-size: 2.4rem !important;

  @media screen and (min-width: 768px) {
    font-size: 2.8rem !important;
    }
}

.fs-section-title {
  font-size: 2rem !important;

  @media screen and (min-width: 768px) {
    font-size: 2.2rem !important;
    }
}

.fs-accordion-header {
  font-size: 1.6rem !important;

  @media screen and (min-width: 768px) {
    font-size: 1.8rem !important;
    }
}

.fs-accordion-body {
  font-size: 1.4rem !important;

  @media screen and (min-width: 768px) {
    font-size: 1.6rem !important;
    }
}

.fs-footer {
  font-size: 1.2rem !important;

  @media screen and (min-width: 768px) {
    font-size: 1.4rem !important;
    }
}

.fs-double-title-up {
  font-size: 3.2rem !important;
}

.fs-double-title-down {
  font-size: 4.5rem !important;
}